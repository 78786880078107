const vDragSort = {

    onDragStart(event, el, binding) {
        const list = binding.value.listSelector ? el.querySelector(binding.value.listSelector) : el;
        list.classList.add('drag-sorting')
        if (el.__o_vDragSort.dragItem) {
            const clone = event.target.cloneNode(true);
            clone.style.visability = 'hidden';
            event.dataTransfer.setData('text', el.__o_vDragSort.dragItem.dataset?.url ?? 'dragsortitem');
        }
        if (typeof binding.value.onDragStart) { binding.value.onDragStart(event, el.__o_vDragSort.dragState);}
    },
    onDragOver(event, el, binding) {
        const list = binding.value.listSelector ? el.querySelector(binding.value.listSelector) : el;
        if (el.__o_vDragSort.dragItem) {
            let offset, position;
            if (binding.modifiers.horizontal) {
                position = event.currentTarget.getBoundingClientRect().width;
                offset = event.offsetX;
            } else {
                position = event.currentTarget.getBoundingClientRect().height;
                offset = event.offsetY;
            }

            const dragItemIndex = Array.from(el.__o_vDragSort.dragItem.parentNode.children).indexOf(el.__o_vDragSort.dragItem);
            const targetIndex = Array.from(event.currentTarget.parentNode.children).indexOf(event.currentTarget);
            if (dragItemIndex !== targetIndex) {
                el.__o_vDragSort.dragState = 'moved';
            }

            let insertElement = null;
            if (position / 2 < offset) {
                // list.insertBefore(el.__o_vDragSort.dragItem, event.currentTarget.nextSibling);
                insertElement = event.currentTarget.nextSibling;
            } else {
                // list.insertBefore(el.__o_vDragSort.dragItem, event.currentTarget);
                insertElement = event.currentTarget;
            }
            if (el.__o_vDragSort.dragItem.debounce) { window.clearTimeout(el.__o_vDragSort.dragItem); }
            el.__o_vDragSort.debounce = window.setTimeout(() => {
                if (el.__o_vDragSort.dragItem) {
                    list.insertBefore(el.__o_vDragSort.dragItem, insertElement);
                }
                if (el.__o_vDragSort?.debounce) {
                    el.__o_vDragSort.debounce = undefined;
                }
            }, 50);

            event.preventDefault();
            event.stopPropagation();
        }
    },
    onDragEnter(event, el, binding) {
        if (el.__o_vDragSort.dragItem) {
            window.requestAnimationFrame(() => {
                el.querySelectorAll('.drag-sort-over').forEach(item => item.classList.remove('drag-sort-over'));
                event.target.closest(binding.value.selector).classList.add('drag-sort-over');
            });
            event.preventDefault();
            event.stopPropagation();
        }
    },
    onDragLeave(event, el, binding) {
        if (el.__o_vDragSort.dragItem) {
            el.querySelector('.drag-sort-over')?.classList.remove('drag-sort-over');
            event.preventDefault();
            event.stopPropagation();
        }
    },
    onDrag(event, el, binding) {
        if (el.__o_vDragSort.dragState) {
            event.preventDefault();
            event.stopPropagation();
        }
    },
    onDragEnd(event, el, binding) {
        const list = binding.value.listSelector ? el.querySelector(binding.value.listSelector) : el;
        list.classList.remove('drag-sorting');
        if (typeof binding.value.onDragEnd) { binding.value.onDragEnd(event, el.__o_vDragSort.dragState)}
        binding.dir.clearDragSort(el, binding);
        el.__o_vDragSort.dragState = null;
        el.__o_vDragSort.dragItem = null;
    },
    onDrop(event, el, binding) {
        const item = el.querySelector('.drag-sort-moving');
        el.__o_vDragSort.dragState = 'dropped';
        if (typeof binding.value.onDrop === 'function') {
            binding.value.onDrop(event, item);
        }
    },
    onMouseDown(event, el, binding) {
        if (!event.currentTarget.draggable) { return; }

        el.__o_vDragSort.dragItem = event.target.closest(binding.value.selector);
        el.__o_vDragSort.dragItem.classList.add('drag-sort-moving');
    },
    onMouseUp(event, el, binding) {
        binding.dir.clearDragSort(el, binding);
    },

    clearDragSort(el, binding) {
        el.querySelectorAll('.drag-sort-moving').forEach(item => item.classList.remove('drag-sort-moving'));
        el.__o_vDragSort.dragItem?.classList.remove('drag-sort-moving');
    },

    mounted(el, binding, vnode, prevVnode) {
        el.__o_vDragSort = {};
        el.querySelectorAll(binding.value.selector).forEach(element => {
            element.draggable = true;
            element.ondragstart = (e) => binding.dir.onDragStart(e, el, binding);
            element.ondragover = (e) => binding.dir.onDragOver(e, el, binding);
            element.ondragenter = (e) => binding.dir.onDragEnter(e, el, binding);
            element.ondragleave = (e) => binding.dir.onDragLeave(e, el, binding);
            element.ondrag = (e) => binding.dir.onDrag(e, el, binding);
            element.ondragend = (e) => binding.dir.onDragEnd(e, el, binding);
            element.ondrop = (e) => binding.dir.onDrop(e, el, binding);
            element.onmousedown = (e) => binding.dir.onMouseDown(e, el, binding);
            element.onmouseup = (e) => binding.dir.onMouseUp(e, el, binding);
        });
    },

    updated(el, binding, vnode, prevVnode) { 
        if(!el.__o_vDragSort) { el.__o_vDragSort = {}; console.warn('Dragsort state reset')}
        el.querySelectorAll(binding.value.selector).forEach(element => {
            element.draggable = true;
            element.ondragstart = (e) => binding.dir.onDragStart(e, el, binding);
            element.ondragover = (e) => binding.dir.onDragOver(e, el, binding);
            element.ondragenter = (e) => binding.dir.onDragEnter(e, el, binding);
            element.ondragleave = (e) => binding.dir.onDragLeave(e, el, binding);
            element.ondrag = (e) => binding.dir.onDrag(e, el, binding);
            element.ondragend = (e) => binding.dir.onDragEnd(e, el, binding);
            element.ondrop = (e) => binding.dir.onDrop(e, el, binding);
            element.onmousedown = (e) => binding.dir.onMouseDown(e, el, binding);
            element.onmouseup = (e) => binding.dir.onMouseUp(e, el, binding);
        });
    },

    unmounted(el, binding, vnode, prevVnode) { }
};

export default vDragSort;